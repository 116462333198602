import { getNumberDecimalSeparator, getNumberGroupSeparator } from './cultureUtils'

export function simplifyNumber(num: number, rawNumThreshold: number = 10000): string {
  const numberString = num.toString()
  let formattedString

  let separator = getNumberDecimalSeparator()
  if (num >= rawNumThreshold) {
    let lastLetter = num >= 1000000000 ? 'B' : num >= 1000000 ? 'M' : 'K'
    let indexLength =
      num < 10 * 1000
        ? 1
        : num < 100 * 1000
        ? 2
        : num < 1 * 1000 * 1000
        ? 3
        : num < 10 * 1000 * 1000
        ? 1
        : num < 100 * 1000 * 1000
        ? 2
        : 3

    formattedString =
      numberString.substring(0, indexLength) +
      (numberString.substring(indexLength, indexLength + 1) == '0' || indexLength == 3
        ? ''
        : separator + numberString.substring(indexLength, indexLength + 1)) +
      lastLetter
  } else {
    const groupSeparator = getNumberGroupSeparator()
    formattedString =
      num >= 1000
        ? numberString.substring(0, 1) +
          (groupSeparator === ',' ? groupSeparator : ' ') +
          numberString.substring(1, 4)
        : num.toLocaleString(navigator.language)
  }

  return formattedString
}

enum FormatKMB {
  Trillion = 1 * 1000 * 1000 * 1000 * 1000,
  Billion = 1 * 1000 * 1000 * 1000,
  Million = 1 * 1000 * 1000,
  Thousand = 1 * 1000,
}

export function simplifyCosRewardAmount(num: number): string {
  let formatType =
    num >= FormatKMB.Trillion
      ? FormatKMB.Trillion
      : num >= FormatKMB.Billion
      ? FormatKMB.Billion
      : num >= 10 * 1000 * 1000
      ? FormatKMB.Million
      : num >= 10 * 1000
      ? FormatKMB.Thousand
      : undefined

  if (formatType) {
    const simplified = num / formatType
    const lastLetter =
      formatType === FormatKMB.Trillion
        ? 'T'
        : formatType === FormatKMB.Billion
        ? 'B'
        : formatType === FormatKMB.Million
        ? 'M'
        : 'K'
    const rounded =
      formatType && formatType !== FormatKMB.Thousand && simplified <= 100
        ? (Math.floor(simplified * 10) / 10).toString()
        : Math.floor(simplified).toString()

    return rounded + lastLetter
  } else {
    return Math.floor(num).toString()
  }
}

export function shortenNumber(num: number, decimalPlaces?: number, textOverflow?: boolean): string {
  if (num.toString().indexOf('.') === -1) {
    return num.toLocaleString()
  }

  if (textOverflow === undefined) {
    textOverflow = true
  }

  decimalPlaces = decimalPlaces ?? 2
  const splitNum = num.toString().split('.')

  const decimalsText = splitNum[1].substring(0, decimalPlaces)
  const zeroes = [...Array(decimalPlaces)].map((_) => '0').join('')
  const shortened =
    Number(splitNum[0]).toLocaleString() +
    (decimalsText !== zeroes
      ? getNumberDecimalSeparator() +
        decimalsText +
        (splitNum[1].length > decimalPlaces && textOverflow ? '...' : '')
      : '')

  return shortened
}

/* Scale a value from one range to another
 * Example of use:
 *
 * // Convert 33 from a 0-100 range to a 0-65535 range
 * var n = scaleValue(33, [0,100], [0,65535]);
 *
 * // Ranges don't have to be positive
 * var n = scaleValue(0, [-50,+50], [0,65535]);
 *
 * Ranges are defined as arrays of two values, inclusive
 *
 * The ~~ trick on return value does the equivalent of Math.floor, just faster.
 *
 */
export function scaleValue(value: number, from: [number, number], to: [number, number]) {
  var scale = (to[1] - to[0]) / (from[1] - from[0])
  var capped = Math.min(from[1], Math.max(from[0], value)) - from[0]
  return ~~(capped * scale + to[0])
}

/**
 * Generates a random value from the specified range.
 */
export const randomFromRange = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min
