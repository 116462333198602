import type {
  PurchaseCategory,
  PurchaseType,
  RewardType,
} from '@g4g/graphql/src/shop/__generated__/globalTypes'

// Google Tag Manager
declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export interface RewardProgress {
  amount?: number
  gamePctg?: number
  cartPctg?: number
  nextDing?: number
  isOverNextDing?: boolean
}

export interface RewardsProgress {
  [RewardType.SalePoints]: RewardProgress
  [RewardType.VipExp]: RewardProgress
}

export interface TickFilter {
  name: string
  id: string
  iconPath?: string
}

export enum SkinShopItemId {
  ExclusiveModelBoost = 'exclusivemodelboost',
  MysteryChestSmartBoost = 'mysterychestsmartboost',
  MonthlyHeroSkin = 'monthlyheroskin',
  MonthlyPetSkin = 'monthlypetskin',
  MonthlyBuildingSkin = 'monthlybuildingskin',
}

export type AllowedTickFilter = RewardType | PurchaseType | PurchaseCategory
export type AllowedTickFilterReplacements = {
  [k in AllowedTickFilter]?: { singular?: boolean; name?: string }
}
