export type SpecialEvent = SeasonalEvent | CurrencyEvent

export enum SeasonalEvent {
  AprilFools = 'AprilFools',
  AtheneBirthday = 'AtheneBirthday',
  BlackFriday = 'BlackFriday',
  CarnivalEvent = 'CarnivalEvent',
  ChineseNewYear = 'ChineseNewYear',
  Christmas = 'Christmas',
  CosCon = 'CosCon',
  EarthDay = 'EarthDay',
  Easter = 'Easter',
  Event420 = 'Event420',
  FathersDay = 'FathersDay',
  FourthOfJuly = 'FourthOfJuly',
  Halloween = 'Halloween',
  HumanitarianDay = 'HumanitarianDay',
  MayDay = 'MayDay',
  MoonLandingEvent = 'MoonLandingEvent',
  MothersDay = 'MothersDay',
  NewYear = 'NewYear',
  Oktoberfest = 'Oktoberfest',
  PiDay = 'PiDay',
  SaintNicholas = 'SaintNicholas',
  SaintPatricksDay = 'SaintPatricksDay',
  TeachersDay = 'TeachersDay',
  Thanksgiving = 'Thanksgiving',
  ValentinesDay = 'ValentinesDay',
}

export const seasonEventCurrency: { [key: string]: string } = {
  AprilFools: 'Emojis',
  AtheneBirthday: 'Cakes',
  BlackFriday: 'Cash',
  CarnivalEvent: 'Masks',
  ChineseNewYear: 'Lantern',
  Christmas: 'Snowmen',
  CosCon: 'Best Games',
  EarthDay: 'Globes',
  Easter: 'Eggs',
  FathersDay: 'Footballs',
  Halloween: 'Pumpkin',
  HumanitarianDay: 'Hearts',
  MayDay: 'Drinks',
  MoonLandingEvent: 'Cheese',
  MothersDay: 'Flowers',
  NewYear: 'Fireworks',
  Oktoberfest: 'Beers',
  PiDay: 'Pies',
  SaintNicholas: 'Candies',
  SaintPatricksDay: 'Jars',
  TeachersDay: 'Pencils',
  Thanksgiving: 'Turkeys',
  ValentinesDay: 'Chocolate',
}

export enum CurrencyEvent {
  AtheneCoin = 'AtheneCoin',
  BljTicket = 'BljTicket',
  CommunityChip = 'CommunityChip',
  CasinoChip = 'CasinoChip',
  MegaChip = 'MegaChip',
  MegaMerchant = 'MegaMerchant', // TODO(Mitchell): missing an icon for this
  PogChampScroll = 'PogChampScroll',
}

// https://docs.google.com/spreadsheets/d/1xJzXMRtgqq831SgxB_f18Jih9yenlrwyC_fXK_8IfMw/edit#gid=1321207497
export const specialEventsCalendar: { [key: string]: SpecialEvent } = {
  '2021-12-16': CurrencyEvent.MegaChip,
  '2021-12-23': SeasonalEvent.Christmas,
  '2021-12-30': SeasonalEvent.NewYear,
  '2022-01-07': CurrencyEvent.CasinoChip,
  '2022-01-14': CurrencyEvent.MegaMerchant,
  '2022-01-21': CurrencyEvent.BljTicket,
  '2022-01-28': SeasonalEvent.ChineseNewYear,
  '2022-02-04': CurrencyEvent.CommunityChip,
  '2022-02-11': SeasonalEvent.ValentinesDay,
  '2022-02-18': CurrencyEvent.MegaMerchant,
  '2022-02-25': SeasonalEvent.CarnivalEvent,
  '2022-03-04': CurrencyEvent.PogChampScroll,
  '2022-03-11': SeasonalEvent.PiDay,
  '2022-03-18': SeasonalEvent.SaintPatricksDay,
  '2022-03-25': CurrencyEvent.AtheneCoin,
  '2022-04-01': SeasonalEvent.AprilFools,
  '2022-04-08': CurrencyEvent.MegaMerchant,
  '2022-04-15': SeasonalEvent.Easter,
  '2022-04-22': SeasonalEvent.EarthDay,
  '2022-04-29': SeasonalEvent.MayDay,
  '2022-05-06': SeasonalEvent.MothersDay,
  '2022-05-13': CurrencyEvent.MegaChip,
  '2022-05-20': CurrencyEvent.CommunityChip,
  '2022-05-27': CurrencyEvent.CasinoChip,
  '2022-06-03': SeasonalEvent.AtheneBirthday,
  '2022-06-10': CurrencyEvent.PogChampScroll,
  '2022-06-17': SeasonalEvent.FathersDay,
  '2022-06-24': CurrencyEvent.MegaMerchant,
  '2022-07-01': SeasonalEvent.FourthOfJuly,
  '2022-07-15': SeasonalEvent.MoonLandingEvent,
  '2022-07-22': CurrencyEvent.BljTicket,
  '2022-07-29': CurrencyEvent.CasinoChip,
  '2022-08-19': SeasonalEvent.HumanitarianDay,
  '2022-08-26': CurrencyEvent.MegaMerchant,
  '2022-09-16': SeasonalEvent.Oktoberfest,
  '2022-09-30': SeasonalEvent.TeachersDay,
  '2022-10-21': SeasonalEvent.CosCon,
  '2022-10-28': SeasonalEvent.Halloween,
  '2022-11-18': SeasonalEvent.Thanksgiving,
  '2022-11-25': SeasonalEvent.BlackFriday,
  '2022-12-02': SeasonalEvent.SaintNicholas,
  '2022-12-23': SeasonalEvent.Christmas,
  '2022-12-30': SeasonalEvent.NewYear,

  // 2023
  // January
  '2023-01-07': CurrencyEvent.MegaMerchant,
  '2023-01-14': CurrencyEvent.MegaMerchant,
  '2023-01-21': SeasonalEvent.ChineseNewYear,
  '2023-01-28': CurrencyEvent.MegaMerchant,

  // February
  '2023-02-04': CurrencyEvent.MegaMerchant,
  '2023-02-11': SeasonalEvent.ValentinesDay,
  '2023-02-18': SeasonalEvent.CarnivalEvent,
  '2023-02-25': CurrencyEvent.MegaMerchant,

  // March
  '2023-03-04': CurrencyEvent.MegaMerchant,
  '2023-03-11': SeasonalEvent.PiDay,
  '2023-03-18': SeasonalEvent.SaintPatricksDay,
  '2023-03-25': CurrencyEvent.MegaMerchant,

  // April
  '2023-04-01': SeasonalEvent.AprilFools,
  '2023-04-08': SeasonalEvent.Easter,
  '2023-04-15': CurrencyEvent.MegaMerchant,
  '2023-04-22': SeasonalEvent.EarthDay,

  // May
  '2023-05-29': SeasonalEvent.MayDay,
  '2023-05-06': CurrencyEvent.MegaMerchant,
  '2023-05-13': SeasonalEvent.MothersDay,
  '2023-05-20': CurrencyEvent.MegaMerchant,
  '2023-05-27': CurrencyEvent.MegaMerchant,

  // June
  '2023-06-03': SeasonalEvent.AtheneBirthday,
  '2023-06-10': CurrencyEvent.MegaMerchant,
  '2023-06-17': SeasonalEvent.FathersDay,
  '2023-06-24': CurrencyEvent.MegaMerchant,

  // July
  '2023-07-01': SeasonalEvent.FourthOfJuly,
  '2023-07-08': CurrencyEvent.MegaMerchant,
  '2023-07-15': SeasonalEvent.MoonLandingEvent,
  '2023-07-22': CurrencyEvent.MegaMerchant,

  // August
  '2023-08-29': CurrencyEvent.MegaMerchant,
  '2023-08-05': CurrencyEvent.MegaMerchant,
  '2023-08-12': CurrencyEvent.MegaMerchant,
  '2023-08-19': SeasonalEvent.HumanitarianDay,
  '2023-08-26': CurrencyEvent.MegaMerchant,

  // September
  '2023-09-02': CurrencyEvent.MegaMerchant,
  '2023-09-09': CurrencyEvent.MegaMerchant,
  '2023-09-16': SeasonalEvent.Oktoberfest,
  '2023-09-23': CurrencyEvent.MegaMerchant,

  // October
  '2023-10-30': SeasonalEvent.TeachersDay,
  '2023-10-07': CurrencyEvent.MegaMerchant,
  '2023-10-14': CurrencyEvent.MegaMerchant,
  '2023-10-21': SeasonalEvent.CosCon,
  '2023-10-28': SeasonalEvent.Halloween,

  // November
  '2023-11-04': CurrencyEvent.MegaMerchant,
  '2023-11-11': CurrencyEvent.MegaMerchant,
  '2023-11-18': SeasonalEvent.Thanksgiving,
  '2023-11-25': SeasonalEvent.BlackFriday,

  // December
  '2023-12-02': SeasonalEvent.SaintNicholas,
  '2023-12-09': CurrencyEvent.MegaMerchant,
  '2023-12-16': CurrencyEvent.MegaMerchant,
  '2023-12-23': SeasonalEvent.Christmas,
  '2023-12-30': SeasonalEvent.NewYear,

  /*[[[cog
    currentYear = ""
    currentMonth = ""
    currencyEvents = [
      Calendary.CalendarEventBadLuckJackpot,
      Calendary.CalendarEventCasino,
      Calendary.CalendarEventAtheneCoin,
      Calendary.CalendarEventPogChamp,
      Calendary.CalendarEventMegaChip,
      Calendary.CalendarEventCommunityChip,
      Calendary.CalendarEventMegaMerchant,
    ]
    evt = None
    prevKey = None
    for key in Calendary.CalendarEventDates:
      # YYYY-MM-DD
      [year, month, day] = key.split("-")
      if int(year) < 2024:
        continue

      event = str(Calendary.CalendarEventDates[key][0])
      if event in Calendary.ObsoleteEvents:
        continue
      
      names = {
        "Valentine": "ValentinesDay",
        "Carnival": "CarnivalEvent",
        "IndependanceDay": "FourthOfJuly",
        "MoonLanding": "MoonLandingEvent",
      }


      # for event in Calendary.CalendarEventDates[key]:
      if prevKey:
        cog.out(f"\"{prevKey}\": ")
        e = names.get(event, event)
        if event in currencyEvents:
          cog.outl(f"    CurrencyEvent.{e},")
        else:
          cog.outl(f"    SeasonalEvent.{e},")
      prevKey = f"{year}-{month.zfill(2)}-{day.zfill(2)}"

      # Group line breaks
      if year > currentYear:
        if currentYear != "":
          cog.outl("")

        cog.outl(f"//%s" % year)
      elif currentMonth != "" and int(month) > int(currentMonth):
        cog.outl("")

      currentYear = year
      currentMonth = month

    ]]]*/
  //2024
  '2024-01-05': CurrencyEvent.MegaMerchant,
  '2024-01-12': CurrencyEvent.MegaMerchant,
  '2024-01-19': CurrencyEvent.MegaMerchant,
  '2024-01-26': CurrencyEvent.MegaMerchant,

  '2024-02-02': SeasonalEvent.ChineseNewYear,
  '2024-02-09': SeasonalEvent.ValentinesDay,
  '2024-02-16': SeasonalEvent.CarnivalEvent,
  '2024-02-23': CurrencyEvent.MegaMerchant,

  '2024-03-01': CurrencyEvent.MegaMerchant,
  '2024-03-08': SeasonalEvent.PiDay,
  '2024-03-15': SeasonalEvent.SaintPatricksDay,
  '2024-03-22': CurrencyEvent.MegaMerchant,
  '2024-03-29': SeasonalEvent.AprilFools,

  '2024-04-05': SeasonalEvent.Easter,
  '2024-04-12': CurrencyEvent.MegaMerchant,
  '2024-04-19': SeasonalEvent.EarthDay,
  '2024-04-26': SeasonalEvent.MayDay,

  '2024-05-03': CurrencyEvent.MegaMerchant,
  '2024-05-10': SeasonalEvent.MothersDay,
  '2024-05-17': CurrencyEvent.MegaMerchant,
  '2024-05-24': CurrencyEvent.MegaMerchant,
  '2024-05-31': CurrencyEvent.MegaMerchant,

  '2024-06-07': SeasonalEvent.AtheneBirthday,
  '2024-06-14': SeasonalEvent.FathersDay,
  '2024-06-21': CurrencyEvent.MegaMerchant,
  '2024-06-28': SeasonalEvent.FourthOfJuly,

  '2024-07-05': CurrencyEvent.MegaMerchant,
  '2024-07-12': CurrencyEvent.MegaMerchant,
  '2024-07-19': SeasonalEvent.MoonLandingEvent,
  '2024-07-26': CurrencyEvent.MegaMerchant,

  '2024-08-02': CurrencyEvent.MegaMerchant,
  '2024-08-09': CurrencyEvent.MegaMerchant,
  '2024-08-16': SeasonalEvent.HumanitarianDay,
  '2024-08-23': CurrencyEvent.MegaMerchant,
  '2024-08-30': CurrencyEvent.MegaMerchant,

  '2024-09-06': CurrencyEvent.MegaMerchant,
  '2024-09-13': CurrencyEvent.MegaMerchant,
  '2024-09-20': SeasonalEvent.Oktoberfest,
  '2024-09-27': CurrencyEvent.MegaMerchant,

  '2024-10-04': SeasonalEvent.TeachersDay,
  '2024-10-11': CurrencyEvent.MegaMerchant,
  '2024-10-18': SeasonalEvent.CosCon,
  '2024-10-25': SeasonalEvent.Halloween,

  '2024-11-01': CurrencyEvent.MegaMerchant,
  '2024-11-08': CurrencyEvent.MegaMerchant,
  '2024-11-15': CurrencyEvent.MegaMerchant,
  '2024-11-22': SeasonalEvent.Thanksgiving,
  '2024-11-29': SeasonalEvent.BlackFriday,

  '2024-12-06': SeasonalEvent.SaintNicholas,
  '2024-12-13': CurrencyEvent.MegaMerchant,
  '2024-12-20': SeasonalEvent.Christmas,
  '2024-12-27': SeasonalEvent.NewYear,

  //2025
  //[[[end]]]
}
