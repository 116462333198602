import {
  RewardType,
  PurchaseCategory,
  PurchaseType,
} from '@g4g/graphql/src/shop/__generated__/globalTypes'

export const VIP_LVL_REQUIREMENTS: {
  entryXp: number
  nextLvlEntryXp: number
  lvl: number
  nextLvl: number
}[] = [
  {
    entryXp: -Infinity,
    nextLvlEntryXp: -1,
    lvl: 0,
    nextLvl: 1,
  },
  {
    entryXp: 0,
    nextLvlEntryXp: 200,
    lvl: 0,
    nextLvl: 1,
  },
  {
    entryXp: 200,
    nextLvlEntryXp: 1100,
    lvl: 1,
    nextLvl: 2,
  },
  {
    entryXp: 1100,
    nextLvlEntryXp: 2300,
    lvl: 2,
    nextLvl: 3,
  },
  {
    entryXp: 2300,
    nextLvlEntryXp: 5800,
    lvl: 3,
    nextLvl: 4,
  },
  {
    entryXp: 5800,
    nextLvlEntryXp: 14600,
    lvl: 4,
    nextLvl: 5,
  },
  {
    entryXp: 14600,
    nextLvlEntryXp: 37300,
    lvl: 5,
    nextLvl: 6,
  },
  {
    entryXp: 37300,
    nextLvlEntryXp: 54000,
    lvl: 6,
    nextLvl: 7,
  },
  {
    entryXp: 54000,
    nextLvlEntryXp: 200000,
    lvl: 7,
    nextLvl: 8,
  },
  {
    entryXp: 200000,
    nextLvlEntryXp: 370000,
    lvl: 8,
    nextLvl: 9,
  },
  {
    entryXp: 370000,
    nextLvlEntryXp: 600000,
    lvl: 9,
    nextLvl: 10,
  },
  {
    entryXp: 600000,
    nextLvlEntryXp: 900000,
    lvl: 10,
    nextLvl: 11,
  },
  {
    entryXp: 900000,
    nextLvlEntryXp: 1340000,
    lvl: 11,
    nextLvl: 12,
  },
  {
    entryXp: 1340000,
    nextLvlEntryXp: 2000000,
    lvl: 12,
    nextLvl: 13,
  },
  {
    entryXp: 2000000,
    nextLvlEntryXp: 10000000,
    lvl: 13,
    nextLvl: 14,
  },
  {
    entryXp: 10000000,
    // There is no lvl15 so the cap of the xp is "infinite"
    nextLvlEntryXp: Number.MAX_SAFE_INTEGER,
    lvl: 14,
    nextLvl: 14,
  },
]

export type VIP_LEVEL_CATEGORY = 1 | 2 | 3 | 4 | 5

export const SALE_POINTS_NEEDED_FOR_FREE_DEAL = 25

// Keep the desired order to be displayed in the
// side bars
export const AVAILABLE_CATEGORY_FILTERS = [
  PurchaseCategory.ExclusiveDeal,
  PurchaseCategory.SpecialDeal,
  PurchaseCategory.Boost,
  PurchaseCategory.Bundle,
  PurchaseType.Subscription,
]

export const AVAILABLE_RESOURCE_FILTERS = [
  RewardType.Gem,
  RewardType.Crypton,
  RewardType.SalePoints,
  RewardType.CommunityChip,
  RewardType.AtheneCoin,
  RewardType.CasinoChip,
  RewardType.ChallengeChip,
  RewardType.BljTicket,
  RewardType.TriHardScroll,
  RewardType.KappaScroll,
  RewardType.PogChampScroll,
  RewardType.MegaChip,
  RewardType.MadnessChip,
  RewardType.InsanityChip,
  RewardType.AscendanceChip,
  RewardType.TTTTVoucher,
  RewardType.LandVoucher,
]
