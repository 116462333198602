/**
 * @returns title case string
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/toTitleCase.md
 * @example
 * toTitleCase('some_database_field_name'); // 'Some Database Field Name'
 * toTitleCase('Some label that needs to be title-cased');
 * // 'Some Label That Needs To Be Title Cased'
 * toTitleCase('some-package-name'); // 'Some Package Name'
 * toTitleCase('some-mixed_string with spaces_underscores-and-hyphens');
 * // 'Some Mixed String With Spaces Underscores And Hyphens'
 */
const toTitleCase = (str: string) =>
  (str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g) ?? [])
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ')

export default toTitleCase
