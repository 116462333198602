import {
  VIP_LEVEL_CATEGORY,
  SALE_POINTS_NEEDED_FOR_FREE_DEAL,
  AVAILABLE_CATEGORY_FILTERS,
  AVAILABLE_RESOURCE_FILTERS,
  VIP_LVL_REQUIREMENTS,
} from './constants/gameConstants'
import { seasonEventCurrency, SpecialEvent, specialEventsCalendar } from './constants/events'
import { scaleValue } from './numberUtils'
import type { GetShopItems_getShopItems_lootRewardPackage_lootRewards } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { GetUsers_getUsers_userState } from '@g4g/graphql/src/shop/__generated__/GetUsers'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import {
  AllowedTickFilter,
  AllowedTickFilterReplacements,
  RewardsProgress,
  SkinShopItemId,
  TickFilter,
} from './types'
import pluralize from './pluralize'
import fromCamelCase from './fromCamelCase'
import toTitleCase from './toTitleCase'

const skinResetPeriods: { [k in string]?: 'weekly' | 'monthly' } = {
  exclusivemodelboost: 'weekly',
  mysterychestsmartboost: 'weekly',
  monthlybuildingskin: 'monthly',
  monthlyheroskin: 'monthly',
  monthlypetskin: 'monthly',
}

export const getShopItemResetPeriod = (shopItemId: string) => skinResetPeriods[shopItemId]

export const shopItemWithSkin = (shopItemId: string) =>
  shopItemId === SkinShopItemId.ExclusiveModelBoost ||
  shopItemId === SkinShopItemId.MysteryChestSmartBoost ||
  shopItemId === SkinShopItemId.MonthlyPetSkin ||
  shopItemId === SkinShopItemId.MonthlyBuildingSkin ||
  shopItemId === SkinShopItemId.MonthlyHeroSkin

export const isSalePoints = (loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards) =>
  loot.rewardType === RewardType.SalePoints
export const isFreeDeal = (loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards) =>
  isSalePoints(loot) && loot.amount === SALE_POINTS_NEEDED_FOR_FREE_DEAL

const noRewardIconList = [RewardType.Hero, RewardType.Custom]
// Make sure we have some type of icon for the small
// custom + rewards
const substitutes: { [k in string]: RewardType } = {
  extragems: RewardType.Gem,
  friendboost: RewardType.Gem,
} as const
/**
 * @returns the Reward icon name if any, otherwise undefined
 * because certain Rewards have the icon inside the container
 * image
 */
export const lootRewardIcon = (
  loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards,
  small?: boolean
): string | undefined => {
  if (small && loot.customImg) {
    return substitutes[loot.customImg]?.toLowerCase()
  }

  if (isFreeDeal(loot) || noRewardIconList.includes(loot.rewardType)) {
    return undefined
  }

  switch (loot.rewardType) {
    case RewardType.SeasonalEventItem:
      return getSpecialEvent()?.toLowerCase() || loot.rewardType.toLowerCase()

    case RewardType.VipExp: {
      const vipCategory = getVipCategory({ exp: loot.amount })
      return `vip_category_icon_${vipCategory}`
    }

    default:
      return loot.rewardType.toLowerCase()
  }
}

export const isSkinLootImg = (img?: string | null) => img?.startsWith('http')

export const lootRewardContainer = (
  loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards
): string => {
  if (loot.rewardType === RewardType.Custom && loot.customImg) {
    return isSkinLootImg(loot.customImg) ? loot.customImg : `custom_${loot.customImg}`
  }

  if (loot.rewardType === RewardType.SalePoints) {
    const isExactFreeDeal = loot.amount === SALE_POINTS_NEEDED_FOR_FREE_DEAL
    return isExactFreeDeal ? 'salepoints_free_deal' : 'salepoints_container'
  }

  if (loot.rewardType === RewardType.VipExp) {
    const vipCategory = getVipCategory({ exp: loot.amount })
    return `vip_container_${vipCategory}`
  }

  if (loot.rewardType === RewardType.Hero) {
    return 'hero_container'
  }

  return 'standard_container'
}

/**
 * @returns { name: Reward Type, id: rewardtype } from  RewardType | PurchaseCategory[]
 * @example
 * generateTickFilters([RewardType]) // [{ name: Special Deals, id: specialdeal }, ...]
 */
const generateTickFilters = (
  type: 'category' | 'resource',
  available: AllowedTickFilter[],
  replacements?: AllowedTickFilterReplacements
): TickFilter[] =>
  available.map((c) => {
    const id = c.toLowerCase()
    const isSingular = replacements && replacements[c]?.singular
    const replacementName = replacements && replacements[c]?.name
    const pluralized = isSingular ? c : pluralize(2, c)

    return {
      id,
      name: replacementName ? replacementName : toTitleCase(fromCamelCase(pluralized, ' ')),
      ...(type === 'resource' ? { iconPath: `/shared-assets/img/resources/${id}.png` } : {}),
    }
  })

const replacements: AllowedTickFilterReplacements = {
  Crypton: { singular: true },
  SalePoints: { singular: true },
  TTTTVoucher: { name: 'TTTT Voucher' },
  LandVoucher: { singular: true },
  BljTicket: { name: 'VIP Tickets' },
  CasinoChip: { name: 'Get Lucky Chips' },
  ChallengeChip: { name: 'Casino Chips' },
}

export const tickFilters = (): {
  category: TickFilter[]
  resource: TickFilter[]
} => {
  const resource = generateTickFilters('resource', AVAILABLE_RESOURCE_FILTERS, replacements)
  const specialEvent = getSpecialEvent()

  return {
    category: generateTickFilters('category', AVAILABLE_CATEGORY_FILTERS),
    resource: [
      ...resource,
      ...(specialEvent && specialEvent.toString() in seasonEventCurrency
        ? [
            {
              name: 'Seasonal Event',
              id: RewardType.SeasonalEventItem.toLowerCase(),
              iconPath: `/shared-assets/img/resources/${specialEvent.toLowerCase()}.png`,
            } as TickFilter,
          ]
        : []),
    ],
  }
}

export const getSpecialEvent = (): SpecialEvent | undefined => {
  const dates = Object.keys(specialEventsCalendar)

  const currentEventDate = dates.find(
    (dateString, i) =>
      Date.now() >= new Date(dateString).getTime() && Date.now() < new Date(dates[i + 1]).getTime()
  )!
  return specialEventsCalendar[currentEventDate]
}

export const vipExpBgColor = (vipExp: number) =>
  ['#977e5d', '#888c97', '#bfa956', '#337880', '#575a8f'][getVipCategory({ exp: vipExp }) - 1]

export const vipExpColor = (vipExp: number) =>
  ['#f1d9b5', '#d1fffe', '#ffc300', '#75d8e4', '#bda4ff'][getVipCategory({ exp: vipExp }) - 1]

/**
 * @returns the vip exp level from vipExp
 * @example
 * vipExpLevel(0)    // 0
 * vipExpLevel(200)  // 1
 */
export const vipExpLevel = (vipExp: number) => getVipLvlRequirement(vipExp).lvl

/**
 * @returns the vip exp category from the vip exp level
 * @example
 * getVipCategoryFromLevel(0)  // 1
 * getVipCategoryFromLevel(1)  // 1
 * getVipCategoryFromLevel(14) // 5
 */
export const getVipCategoryFromLevel = (level: number): VIP_LEVEL_CATEGORY =>
  level <= 3 ? 1 : level <= 6 ? 2 : level <= 9 ? 3 : level <= 13 ? 4 : 5

/**
 * @returns the vip exp category from either the vip exp level
 * or the vipExp
 * getVipCategory({ level: 0 })  // 1
 * getVipCategory({ level: 1 })  // 1
 * getVipCategory({ exp: 200 })  // 1
 */
export const getVipCategory = (
  vip: { level: number; exp?: number } | { exp: number; level?: number }
): VIP_LEVEL_CATEGORY =>
  vip.level !== undefined
    ? getVipCategoryFromLevel(vip.level)
    : getVipCategoryFromLevel(vipExpLevel(vip.exp !== undefined ? vip.exp : -1))

/**
 * @returns the closest larger sp value divisible by
 * SALE_POINTS_NEEDED_FOR_FREE_DEAL
 * @example
 * nextSalePointsDing(533) // 550
 * nextSalePointsDing(600) // 625
 * nextSalePointsDing(50)  // 75
 * nextSalePointsDing(0)   // 25
 */
export const nextSalePointsDing = (salePoints: number) =>
  SALE_POINTS_NEEDED_FOR_FREE_DEAL - (salePoints % SALE_POINTS_NEEDED_FOR_FREE_DEAL) + salePoints

/**
 * @returns the sp % 25 pctg on a [0, SALE_POINTS_NEEDED_FOR_FREE_DEAL] range
 * @example
 * salePointsPctg(1)   // 4
 * salePointsPctg(24)  // 96
 * salePointsPctg(25)  // 0
 * salePointsPctg(115) // 60
 */
export const salePointsPctg = (salePoints: number) =>
  ((salePoints % SALE_POINTS_NEEDED_FOR_FREE_DEAL) / SALE_POINTS_NEEDED_FOR_FREE_DEAL) * 100

export const getLastLvlRequirement = () => VIP_LVL_REQUIREMENTS[VIP_LVL_REQUIREMENTS.length - 1]

export const getVipLvlRequirement = (vipExp: number) => {
  const lastRequirement = getLastLvlRequirement()

  if (vipExp >= lastRequirement.entryXp) {
    return lastRequirement
  }

  const requirement = VIP_LVL_REQUIREMENTS.find(
    ({ entryXp, nextLvlEntryXp }) => vipExp >= entryXp && vipExp < nextLvlEntryXp
  )

  if (!requirement) {
    throw new Error('Invalid vipExp amount')
  }

  return requirement
}

/**
 * @returns the index of the next vipExp ding,
 * check VIP_LVL_REQUIREMENTS
 * @example
 * nextVipExpDingAt(250)        // 2
 * nextVipExpDingAt(1000)       // 2
 * nextVipExpDingAt(1100)       // 3
 * nextVipExpDingAt(11_000_000) // 14 max
 * nextVipExpDingAt(Infinity)   // 14 max
 */
export const nextVipExpDingAt = (vipExp: number) => getVipLvlRequirement(vipExp).nextLvl

/**
 * @returns the next closest vip exp ding, if 0 exp then
 * should only need 1 vipExp to reach vip level 1
 * @example
 * nextVipExpDing(250)        // 1100
 * nextVipExpDing(1000)       // 1100
 * nextVipExpDing(2300)       // 2300
 * nextVipExpDing(11_000_000) // 10_000_000 max
 */
export const nextVipExpDing = (vipExp: number) => getVipLvlRequirement(vipExp).nextLvlEntryXp
/**
 * @returns the scaled pctg on a [vipLvlStart, vipLvlEnd] range
 * @example
 * vipExpPctg(199)        // 99.5 end of lvl 2
 * vipExpPctg(200)        // 0 start of lvl 2
 * vipExpPctg(550)        // 38.8 somewhere in lvl 2
 * vipExpPctg(1100)       // 0 start of lvl 3
 * vipExpPctg(9_990_000)  // 99.875 close to max
 * vipExpPctg(10_000_000) // 100 max
 * vipExpPctg(11_000_000) // 100 max
 */
export const vipExpPctg = (vipExp: number) => {
  const requirement = getVipLvlRequirement(vipExp)
  const [start, end] = [requirement.entryXp, requirement.nextLvlEntryXp]

  // Max pctg reached
  if (requirement.lvl === 14) {
    return 100
  }

  return (scaleValue(vipExp, [start, end], [0, end]) / end) * 100
}

export const rewardsProgressCalculator = (
  game: number,
  cart: number,
  nextDingCalculator: (v: number) => number,
  pctgCalculator: (v: number) => number
): RewardsProgress[RewardType.VipExp] | RewardsProgress[RewardType.SalePoints] => {
  // In game sale points/vip exp + shopping cart salepoints/vip exp
  const total = game + cart
  // Next closest in game sale points/vip exp ding
  const nextGameDing = nextDingCalculator(game)
  // True if enough sale points/vip exp added to the cart to ding at least once
  const isOverNextDing = total >= nextGameDing

  return {
    amount: total,
    // The game pctg (orange progress bar) doesn't matter anymore when we ding
    gamePctg: isOverNextDing ? 0 : pctgCalculator(game),
    // The cart pctg (green progress bar) takes over when there is a ding
    // otherwise the cartPctg is calculated from the leftover amount
    cartPctg: isOverNextDing ? pctgCalculator(total) : (cart / (nextGameDing - game)) * 100,
    // The closest next ding to the total game + cart sale points/vip exp
    nextDing: nextDingCalculator(total),
    isOverNextDing,
  }
}

export const rewardsProgress = (
  userState?: GetUsers_getUsers_userState,
  // Empty or no such loot in a cart means 0
  cartSalePoints: number = 0,
  cartVipExp: number = 0
): RewardsProgress | undefined => {
  if (!userState) {
    return undefined
  }

  const values = [cartSalePoints, cartVipExp, userState.salePoints, userState.vipXP]

  if (!values.every((v) => v !== null && v >= 0)) {
    throw new Error(
      'Invalid rewards progress input, sale points/vip exp < 0 or bad userState API response'
    )
  }

  return {
    [RewardType.SalePoints]: {
      ...rewardsProgressCalculator(
        userState.salePoints,
        cartSalePoints,
        nextSalePointsDing,
        salePointsPctg
      ),
    },
    [RewardType.VipExp]: {
      ...rewardsProgressCalculator(userState.vipXP, cartVipExp, nextVipExpDing, vipExpPctg),
    },
  }
}
